body{
    margin: 0;
    padding: 0;
}
.App{
    background-color: rgb(205, 189, 189);
    font-size: 30px;
    text-align: center;
    padding: 2rem;
    font-family: sans-serif;
}